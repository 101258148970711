.dropdown-menu {
  z-index: 55;
  background: red;
  position: fixed;
  margin-left: 112px;
  top: 250px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #1888ff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
   display: none;
  }

  .dropdown-menu {
    z-index: 55;
    background: red;
    position: absolute;
    margin-left:20rem;
    top: 200px;
    list-style: none;
    text-align: start;
  }
  
}
