


.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

/* .nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
} */

.nav-menu{
margin-left: 12%;
margin-bottom: 1.5%;

}

.nav-item {
 
  display: flex;
  align-items: center;
  
}

.nav-links {
 
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 80px;
    left: -120%;
    opacity: 1;
    transition: all 0.5s ease;
    position: fixed;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1.4rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: fixed;
   
    
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: fixed;
    color: #1888ff;
    background-color: black;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }

  .nav-menu{
    
    }
    
}
